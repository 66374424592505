<lib-header />

<main>
  <p class="title">Configurações</p>

  <div class="card">
    <div class="card-content">
      <div class="input-icon">
        <input
          type="text"
          class="form-control"
          placeholder="CR / Unidade"
          [formControl]="search"
        />
        <img src="./../../assets/svg/search.svg" alt="search" />
      </div>

      <button class="search" (click)="searchUnits()">Buscar</button>
    </div>

    <p *ngIf="loading && search.value" class="load">Carregando dados...</p>
    <ul *ngIf="!loading && unitList.length" class="list">
      <li *ngFor="let unit of unitList; trackBy: trackByIndex" class="unit" [ngClass]="{ active: unitSelected?.id === unit.id }">
        <p class="unit-name" (click)="selectUnit(unit)">
          CR:{{ unit.code }} - {{ unit.name }}
        </p>
        <ul *ngIf="unitSelected?.id === unit.id">
          <li class="title">Dispositivos</li>
          <li *ngFor="let device of unit.availableDevices; trackBy: trackByIndex" class="sub" (click)="deviceSelected = device" [ngClass]="{ active: deviceSelected?.id === device.id }">
            <p>{{ device.name }}</p>
          </li>
          <p *ngIf="!unit.availableDevices.length" class="empty">Nenhum dispositivo encontrado</p>
        </ul>
      </li>
    </ul>
    <p *ngIf="!loading && search.value && !unitList.length && searched" class="empty">Nenhuma unidade encontrada</p>
  </div>
  <button
    class="confirm"
    (click)="configDone()"
    [disabled]="!unitSelected || !deviceSelected"
  >
    Confirmar
  </button>
</main>
