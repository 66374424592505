import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PollManagerComponent } from '../../../../lib-ui/src/shared/components/poll-manager/poll-manager.component';
import { IPoll } from '../../../../lib-ui/src/shared/interfaces/poll';
import {
  IPollResponse,
  IResponses,
} from '../../../../lib-ui/src/shared/interfaces/poll-responses';
import { IPartialUnit } from '../../../../lib-ui/src/shared/interfaces/unit';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { IndexedDbAnswersService } from '../shared/services/indexeddb-answers.service';
import { IndexedDbUnitService } from '../shared/services/indexeddb-unit.service';

import { Guid } from 'guid-typescript';
import { lastValueFrom, take } from 'rxjs';
import { PageUnavailableComponent } from '../../../../lib-ui/src/shared/components/page-unavailable/page-unavailable.component';
import { ImageService } from '../shared/services/img-base64.service';
import { IndexedDbPollService } from '../shared/services/indexeddb-poll.service';
import { PollService } from '../shared/services/poll.service';
import { UnitService } from '../shared/services/unit.service';

@Component({
  selector: 'app-poll',
  standalone: true,
  imports: [
    CommonModule,
    PollManagerComponent,
    FooterComponent,
    PageUnavailableComponent,
  ],
  providers: [UnitService],
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly indexedDbAnswersService = inject(IndexedDbAnswersService);
  readonly indexedDbUnitService = inject(IndexedDbUnitService);
  private readonly unitService = inject(UnitService);
  private readonly pollService = inject(PollService);
  private readonly imageService = inject(ImageService);
  private readonly indexedDbPollService = inject(IndexedDbPollService);

  poll!: IPoll;
  unitId!: string;

  unit!: IPartialUnit;
  interval: any;
  intervalOneHour: any;

  lockScreen = true;

  ngOnInit(): void {
    this.poll = this.activatedRoute.snapshot.data['poll'];
    this.unitId = this.activatedRoute.snapshot.paramMap.get('unitId')!;

    this.interval = setInterval(() => {
      this.handlerSchedule();
    }, 15000);

    this.intervalOneHour = setInterval(() => {
      this.getUnit();
    }, 3600000);

    this.getUnitFromIndexdDB();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.intervalOneHour);
  }

  handlerSchedule(): void {
    let lockScreenItem = true;

    this.unit.availableShifts.forEach((shift) => {
      const now = new Date();

      const [initialHoras, initialMinutos] = shift.startTime
        .split(':')
        .map(Number);

      const [endHoras, endMinutos] = shift.endTime.split(':').map(Number);

      const start = new Date(
        new Date().setHours(initialHoras, initialMinutos, 0, 0)
      );
      const end = new Date(new Date().setHours(endHoras, endMinutos, 0, 0));

      if (end < start || end === start) {
        end.setDate(end.getDate() + 1);
      }

      if (now >= start && now <= end) {
        lockScreenItem = false;
      }
    });

    if (!this.unit.availableShifts.length) {
      lockScreenItem = false;
    }

    this.lockScreen = lockScreenItem;
  }

  getUnitFromIndexdDB(): void {
    this.indexedDbUnitService
      .getUnitById(this.activatedRoute.snapshot.paramMap.get('unitId')!)
      .then((unit) => {
        this.unit = unit;
        this.handlerSchedule();
      });
  }

  async getUnit(): Promise<void> {
    if (!navigator.onLine) return;
    const res = await lastValueFrom(this.unitService.getUnits(this.unit.code));
    const unitRes = res.data[0];

    if (unitRes.pollId) {
      const data: IPartialUnit = {
        ...unitRes,
        device: this.unit.device,
      };

      this.addUnitToIndexedDb(data);
    }
  }

  addUnitToIndexedDb(data: IPartialUnit): void {
    this.indexedDbUnitService.addUnit(data).then(() => {
      if (data.pollId !== this.unit.pollId) {
        this.getPoll(data);
      }
      this.getUnitFromIndexdDB();
    });
  }

  async getPoll(dataUnit: IPartialUnit): Promise<void> {
    const { success, data } = await lastValueFrom(
      this.pollService.getPollById(dataUnit.pollId).pipe(take(1))
    );

    if (success) {
      this.imageService.convertImgsURLToBase64(data).then((res) => {
        this.indexedDbPollService.addPoll(res).then(() => {
          this.poll = res;
        });
      });
    }
  }

  async onFinishPoll(responses: IResponses[]): Promise<void> {
    const answers: IPollResponse = {
      id: Guid.create().toString(),
      branchId: this.unitId,
      pollId: this.poll.id,
      deviceId: this.unit.device.id,
      createdAt: new Date().toISOString(),
      pollAnswers: responses,
    };

    this.indexedDbAnswersService
      .addAnswers(answers)
      .then(() => 'Answers added');
  }
}
