import { inject, Injectable } from '@angular/core';

import { IPollResponse } from '../../../../../lib-ui/src/shared/interfaces/poll-responses';
import { EObjectStore } from '../enums/objectStore.enum';
import { StartIndexedDbService } from './indexeddb.service';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbAnswersService {
  private readonly startIndexedDbService = inject(StartIndexedDbService);
  private readonly db: IDBDatabase | null = this.startIndexedDbService.db;

  // Adiciona uma Answers ao banco de dados
  public addAnswers(item: any): Promise<IPollResponse> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readwrite').add(item);

      request.onsuccess = () => resolve(item);

      request.onerror = () =>
        reject(new Error('Erro ao adicionar item ao banco de dados'));
    });
  }

  // Busca Answers por id
  public getAnswerById(answersID: string): Promise<IPollResponse | null> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').get(answersID);

      request.onsuccess = () => resolve(request?.result ?? null);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  // Busca todos Answers
  public getAllAnswersIds(): Promise<IDBValidKey[]> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').getAllKeys();

      request.onsuccess = () => resolve(request?.result);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  // Remove Answer pelo ID
  public deleteAnswersById(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readwrite').delete(id);

      request.onsuccess = () => resolve();
      request.onerror = () =>
        reject(new Error('Erro ao deletar todos os itens'));
    });
  }

  private initTransaction(
    permission: 'readwrite' | 'readonly'
  ): IDBObjectStore {
    return this.db!.transaction([EObjectStore.ANSWERS], permission).objectStore(
      EObjectStore.ANSWERS
    );
  }
}
