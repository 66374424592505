import { inject, Injectable } from '@angular/core';

import { IPartialUnit } from '../../../../../lib-ui/src/shared/interfaces/unit';
import { EObjectStore } from '../enums/objectStore.enum';
import { StartIndexedDbService } from './indexeddb.service';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbUnitService {
  private readonly startIndexedDbService = inject(StartIndexedDbService);
  private readonly db: IDBDatabase | null = this.startIndexedDbService.db;

  // Adiciona um item ao banco de dados
  public addUnit(item: IPartialUnit): Promise<IPartialUnit> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }
      this.clearDB().then(() => {
        const request = this.initTransaction('readwrite').add(item);

        request.onsuccess = () => resolve(item);

        request.onerror = () =>
          reject(new Error('Erro ao adicionar item ao banco de dados'));
      });
    });
  }

  // Busca todos os itens
  public getUnitById(id: string): Promise<IPartialUnit> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').get(id);

      request.onsuccess = () => resolve(request.result);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  public getUnit(): Promise<IPartialUnit | null> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').getAll();

      request.onsuccess = () => resolve(request.result[0] ?? null);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  // Remove um item pelo ID
  public clearDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.db)
        return reject(new Error('Banco de dados não foi inicializado'));

      const request = this.initTransaction('readwrite').clear();

      request.onsuccess = () => resolve();

      request.onerror = () => reject(new Error('Erro ao deletar item'));
    });
  }

  private initTransaction(
    permission: 'readwrite' | 'readonly'
  ): IDBObjectStore {
    return this.db!.transaction([EObjectStore.UNIT], permission).objectStore(
      EObjectStore.UNIT
    );
  }
}
