import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { filter, Subscription } from 'rxjs';

import { AlertComponent } from './shared/components/alert/alert.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterModule, LoadingComponent, AlertComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly loadingService = inject(LoadingService);

  private readonly subs: Subscription[] = [];

  ngOnInit(): void {
    this.subs.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe(() => setTimeout(() => this.loadingService.showLoading(), 0))
    );

    this.subs.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() =>
          setTimeout(() => this.loadingService.closeLoading(), 0)
        )
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
