import { ErrorHandler, Injectable, inject } from '@angular/core';

import { AlertService } from '../alert.service';
import { LoadingService } from '../loading.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly alertService = inject(AlertService);
  private readonly loadingService = inject(LoadingService);

  handleError(error: any): void {
    this.alertService.danger(
      `Error: ${error.message || 'Ocorreu um erro inesperado.'}`
    );

    console.error(error);
    setTimeout(() => this.loadingService.closeLoading(), 0);
  }
}
