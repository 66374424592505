import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export class Alert {
  constructor(
    public readonly alertType: AlertType,
    public readonly id: number,
    public readonly message: string,
    public readonly title?: string
  ) {}
}

export type AlertType = 'success' | 'info' | 'warning' | 'danger';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  protected alert = new BehaviorSubject<Alert | null>(null);

  // Agora retorna um Observable em vez de um valor simples
  getAlert(): Observable<Alert | null> {
    return this.alert.asObservable();
  }

  success(message: string, title?: string): void {
    this.message('success', message, title);
  }

  info(message: string, title?: string): void {
    this.message('info', message, title);
  }

  warning(message: string, title?: string): void {
    this.message('warning', message, title);
  }

  danger(message: string, title?: string): void {
    this.message('danger', message, title);
  }

  private message(alertType: AlertType, message: string, title?: string): void {
    const id = new Date().getTime();
    this.alert.next(new Alert(alertType, id, message, title));
  }
}
