import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { EObjectStore } from '../enums/objectStore.enum';

@Injectable({
  providedIn: 'root',
})
export class StartIndexedDbService {
  private readonly dbName = environment.dbName;
  private readonly dbVersion = environment.dbVersion;

  db: IDBDatabase | null = null;

  constructor() {
    this.initializeIndexDB();
  }

  private initializeIndexDB(): void {
    this.initDb().then(() => console.log('IndexedDB inicializado com sucesso'));
  }

  initDb(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = () =>
        reject(new Error('Erro ao abrir o banco de dados'));

      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onupgradeneeded = () => {
        this.db = request.result;
        if (!this.db.objectStoreNames.contains(EObjectStore.POLL)) {
          const objectStore = this.db.createObjectStore(EObjectStore.POLL, {
            keyPath: 'id',
            autoIncrement: false,
          });
          objectStore.createIndex('id', 'id', { unique: true });
        }

        if (!this.db.objectStoreNames.contains(EObjectStore.UNIT)) {
          const objectStore = this.db.createObjectStore(EObjectStore.UNIT, {
            keyPath: 'id',
            autoIncrement: false,
          });
          objectStore.createIndex('id', 'id', { unique: true });
        }

        if (!this.db.objectStoreNames.contains(EObjectStore.ANSWERS)) {
          const objectStore = this.db.createObjectStore(EObjectStore.ANSWERS, {
            keyPath: 'id',
            autoIncrement: false,
          });
          objectStore.createIndex('id', 'id', { unique: true });
        }

        if (!this.db.objectStoreNames.contains(EObjectStore.FAILEDANSWERS)) {
          const objectStore = this.db.createObjectStore(
            EObjectStore.FAILEDANSWERS,
            {
              keyPath: 'id',
              autoIncrement: false,
            }
          );
          objectStore.createIndex('id', 'id', { unique: true });
        }

        if (!this.db.objectStoreNames.contains(EObjectStore.RESPONSESSENT)) {
          const objectStore = this.db.createObjectStore(
            EObjectStore.RESPONSESSENT,
            {
              keyPath: 'id',
              autoIncrement: false,
            }
          );
          objectStore.createIndex('id', 'id', { unique: true });
        }
      };
    });
  }
}
