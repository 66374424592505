import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IPage, IPoll, IQuestion } from '../../interfaces/poll';
import { IResponses } from '../../interfaces/poll-responses';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'lib-poll-manager',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, HeaderComponent],
  templateUrl: './poll-manager.component.html',
  styleUrls: ['./poll-manager.component.css'],
})
export class PollManagerComponent implements OnInit {
  @Input() poll!: IPoll;
  @Input() isNotUnityDevice: boolean = false;

  indexSelectedPage = 0;
  time = new BehaviorSubject<number>(0);
  interval!: any;

  page!: IPage;
  showBtn = false;
  disabled = true;

  @Output() onFinishPoll = new EventEmitter<IResponses[]>();
  responses: IResponses[] = [];

  ngOnInit(): void {
    this.selectPage(0);
  }

  selectPage(index: number): void {
    if (this.page) {
      this.page.questions.forEach((q) => {
        this.responses.push({
          pageId: this.page.id,
          questionId: q.id!,
          comment: q.type === 3 ? q.value?.[0] ?? null : null,
          pollAnswerValues:
            q.type !== 3
              ? q.value?.map((v) => {
                  return { valueId: v };
                }) ?? []
              : [],
          questionType: q.type as 1 | 2 | 3,
          incomplete: q.incomplete ?? false,
        });
      });
    }

    const pageSelected = this.createPropToAnswer(this.poll.pages[index]);

    if (pageSelected?.questions.length > 1) {
      this.showBtn = true;
    } else if (pageSelected?.questions.some((q) => q.type !== 1)) {
      this.showBtn = true;
    } else if (
      pageSelected?.pageNumber !== 1 &&
      pageSelected?.questions.some((q) => q.type === 1 && !q.requiredAnswer)
    ) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    this.indexSelectedPage = index;
    this.page = pageSelected;
    this.checkDisabled(this.page);
    clearInterval(this.interval);
    this.countTime(this.page);

    if (!this.page) {
      this.onFinishPoll.emit(this.responses);

      this.responses = [];
    }
  }

  selectUniqueAnswer(page: IPage, next: number | null = null): void {
    if (!this.showBtn) {
      this.selectPage(next ? next - 1 : this.indexSelectedPage + 1);
    } else {
      this.checkDisabled(page);
    }
  }

  addAnswerType2(question: IQuestion, id: string): void {
    if (question.value instanceof Array) {
      if (question.value) {
        if (!question.value.some((value) => value === id)) {
          question.value.push(id);
        } else question.value = question.value.filter((item) => item !== id);
      } else {
        question.value = [id];
      }
    }
  }

  createPropToAnswer(page: IPage): IPage {
    if (!page) return page;

    const pageCopy = JSON.parse(JSON.stringify(page)) as IPage;

    pageCopy.questions.forEach((q) => {
      q.value = [];
    });

    return pageCopy;
  }

  checkDisabled(page: IPage, questionText: boolean = false): void {
    let isDisabled = false;
    page?.questions.forEach((Q) => {
      if (Q.requiredAnswer && !Q.value?.length) {
        isDisabled = true;
      }
    });

    this.disabled = isDisabled;

    if (questionText) {
      clearInterval(this.interval);
      this.countTime(page);
    }
  }

  countTime(page: IPage): void {
    if (page) {
      if (page.pageNumber !== 1 && page.timer > 0) {
        this.time.next(page.timer);

        this.interval = setInterval(() => {
          if (this.time.value === 1) {
            page.questions.forEach((q) => {
              if (!q.value?.length) {
                q.incomplete = true;
              }
            });

            clearInterval(this.interval);
            this.selectPage(this.indexSelectedPage + 1);
          } else {
            this.time.next(this.time.value - 1);
          }
        }, 1000);
      }
    } else {
      this.time.next(this.poll.endPage.timer);
      this.interval = setInterval(() => {
        if (this.time.value === 1) {
          clearInterval(this.interval);
          this.selectPage(0);
        } else {
          this.time.next(this.time.value - 1);
        }
      }, 1000);
    }
  }

  trackByQuestionNumber(index: number, question: IQuestion): number {
    return question.number;
  }

  trackByAnswerId(index: number, answer: any): string {
    return answer.id;
  }
}
