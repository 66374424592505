import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  forkJoin,
  from,
  lastValueFrom,
  Observable,
  switchMap,
  take,
} from 'rxjs';
import { IPoll } from '../../../../../lib-ui/src/shared/interfaces/poll';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private readonly httpClient = inject(HttpClient);

  // Função para converter imagem para Base64
  convertToBase64(url: string): Observable<string> {
    // Faz a requisição HTTP para obter a imagem como ArrayBuffer

    return this.httpClient
      .get(`${url}`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        // Converte o ArrayBuffer para Base64 usando FileReader
        switchMap((data: ArrayBuffer) => {
          const blob = new Blob([data], { type: 'image/png' }); // Ajuste o tipo conforme necessário
          const reader = new FileReader();

          // Cria uma Observable que emite o resultado do FileReader
          return from(
            new Observable<string>((observer) => {
              reader.onloadend = () => {
                const base64data = reader.result as string;
                observer.next(base64data);
                observer.complete();
              };
              reader.onerror = (error) => observer.error(error);
              reader.readAsDataURL(blob); // Inicia a leitura do Blob
            })
          );
        })
      );
  }

  async convertImgsURLToBase64(dataPoll: IPoll): Promise<IPoll> {
    const imgPromises: { [key: string]: Observable<string> } = {};

    dataPoll.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        question.answers.forEach((answer, answerIndex) => {
          if (answer.img && !answer.img.includes('data:')) {
            const key = `${pageIndex}.${questionIndex}.${answerIndex}`;
            imgPromises[key] = this.convertToBase64(answer.img);
          }
        });
      });
    });

    if (dataPoll.endPage.img && !dataPoll.endPage.img.includes('data:')) {
      imgPromises['endPage'] = this.convertToBase64(dataPoll.endPage.img);
    }

    if (Object.keys(imgPromises).length) {
      const imgPromisesResolved = await lastValueFrom(
        forkJoin(imgPromises).pipe(take(1))
      );
      if (dataPoll.endPage.img) {
        dataPoll.endPage.img = imgPromisesResolved['endPage'];
      }
      for (const key in imgPromisesResolved) {
        if (imgPromisesResolved.hasOwnProperty(key)) {
          const [pageIndex, questionIndex, answerIndex] = key
            .split('.')
            .map(Number);

          if (dataPoll.pages[pageIndex]) {
            dataPoll.pages[pageIndex].questions[questionIndex].answers[
              answerIndex
            ].img = imgPromisesResolved[key];
          }
        }
      }
    }

    return dataPoll;
  }
}
