import { inject, Injectable } from '@angular/core';

import { EObjectStore } from '../enums/objectStore.enum';
import { StartIndexedDbService } from './indexeddb.service';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbResponseSendService {
  private readonly startIndexedDbService = inject(StartIndexedDbService);
  private readonly db: IDBDatabase | null = this.startIndexedDbService.db;

  // Adiciona um survey ao banco de dados
  public addResponseSend(data: { id: string }): Promise<{ id: string }> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readwrite').add(data);

      request.onsuccess = () => resolve(data);

      request.onerror = () =>
        reject(new Error('Erro ao adicionar item ao banco de dados'));
    });
  }

  // Busca poll por id
  public getAllResponseSend(): Promise<IDBValidKey[]> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').getAllKeys();

      request.onsuccess = () => resolve(request?.result ?? []);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  // Remove todas as polls
  public clearDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readwrite').clear();

      request.onsuccess = () => resolve();
      request.onerror = () =>
        reject(new Error('Erro ao deletar todos os itens'));
    });
  }

  private initTransaction(
    permission: 'readwrite' | 'readonly'
  ): IDBObjectStore {
    return this.db!.transaction(
      [EObjectStore.RESPONSESSENT],
      permission
    ).objectStore(EObjectStore.RESPONSESSENT);
  }
}
