import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { debounceTime, lastValueFrom, Subscription, take } from 'rxjs';
import { HeaderComponent } from '../../../../lib-ui/src/shared/components/header/header.component';
import { IPoll } from '../../../../lib-ui/src/shared/interfaces/poll';
import {
  IAvailableDevices,
  IPartialUnit,
} from '../../../../lib-ui/src/shared/interfaces/unit';
import { AlertService } from '../shared/services/alert.service';
import { DeviceService } from '../shared/services/device.service';
import { ImageService } from '../shared/services/img-base64.service';
import { IndexedDbPollService } from '../shared/services/indexeddb-poll.service';
import { IndexedDbResponseSendService } from '../shared/services/indexeddb-response-send.service';
import { IndexedDbUnitService } from '../shared/services/indexeddb-unit.service';
import { PollService } from '../shared/services/poll.service';
import { UnitService } from '../shared/services/unit.service';
import { LoadingService } from './../shared/services/loading.service';

@Component({
  selector: 'app-initial-setup',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HeaderComponent],
  providers: [UnitService, DeviceService],
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss'],
})
export class InitialSetupComponent implements OnInit, OnDestroy {
  private readonly indexedDbUnitService = inject(IndexedDbUnitService);
  private readonly router = inject(Router);
  private readonly pollService = inject(PollService);
  private readonly indexedDbPollService = inject(IndexedDbPollService);
  private readonly loadingService = inject(LoadingService);
  private readonly imageService = inject(ImageService);
  private readonly unitService = inject(UnitService);
  private readonly deviceService = inject(DeviceService);
  private readonly alertService = inject(AlertService);
  private readonly indexedDbResponseSendService = inject(
    IndexedDbResponseSendService
  );
  private readonly subs: Subscription[] = [];

  loading = false;
  searched = false;
  search = new FormControl('');
  unitList: IPartialUnit[] = [];

  unitSelected: IPartialUnit | null = null;
  deviceSelected: IAvailableDevices | null = null;
  latitude: string | null = null;
  longitude: string | null = null;

  ngOnInit(): void {
    this.subs.push(
      this.search.valueChanges
        .pipe(debounceTime(200))
        .subscribe(() => (this.searched = false))
    );

    this.setPosition();

    this.indexedDbResponseSendService.clearDB().then(() => {});
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  searchUnits(): void {
    if (this.search.value) {
      this.setPosition();
      this.loading = true;
      this.unitSelected = null;
      this.deviceSelected = null;

      this.subs.push(
        this.unitService.getUnits(this.search.value).subscribe((response) => {
          this.searched = true;
          this.unitList = response.data;
          this.loading = false;
        })
      );
    } else {
      this.unitList = [];
    }
  }

  selectUnit(unit: IPartialUnit): void {
    if (!unit.pollId) {
      this.alertService.info(`Não há pesquisa vinculada a esta unidade`);
      this.unitSelected = null;
      this.deviceSelected = null;
    } else {
      this.unitSelected = unit;
      this.deviceSelected = null;
    }

    this.setPosition();
  }

  configDone() {
    this.loadingService.showLoading();
    const data: IPartialUnit = {
      ...this.unitSelected!,
      device: this.deviceSelected!,
      availableDevices: [],
    };

    this.linkDevice(data);
  }

  async linkDevice(data: IPartialUnit): Promise<void> {
    const { success } = await lastValueFrom(
      this.deviceService
        .linkDevice(data.device.id, this.latitude ?? '', this.longitude ?? '')
        .pipe(take(1))
    );

    if (success) {
      this.addUnitToIndexedDb(data);
    }
  }

  addUnitToIndexedDb(data: IPartialUnit): void {
    this.indexedDbUnitService.addUnit(data).then(() => {
      this.getPoll(data);
    });
  }

  async getPoll(dataUnit: IPartialUnit): Promise<void> {
    const { success, data } = await lastValueFrom(
      this.pollService.getPollById(dataUnit.pollId).pipe(take(1))
    );

    if (success) {
      this.convertImgsToBase64(dataUnit, data);
    }
  }

  async convertImgsToBase64(
    dataUnit: IPartialUnit,
    dataPoll: IPoll
  ): Promise<void> {
    this.imageService
      .convertImgsURLToBase64(dataPoll)
      .then((res) => this.createPoll(dataUnit, res));
  }

  createPoll(dataUnit: IPartialUnit, dataPoll: IPoll): void {
    this.indexedDbPollService.addPoll(dataPoll).then(() => {
      this.router.navigate([`../unit/${dataUnit.id}/poll/${dataUnit.pollId}`]);
    });
  }

  setPosition(): void {
    this.getCurrentPosition().then((position) => {
      this.latitude = position.coords.latitude.toString();
      this.longitude = position.coords.longitude.toString();
    });
  }

  getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    });
  }

  trackByIndex(index: number): number {
    return index;
  }
}
