import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPoll } from '../../../../../lib-ui/src/shared/interfaces/poll';
import { IPollResponse } from '../../../../../lib-ui/src/shared/interfaces/poll-responses';
import { IResponse } from '../../../../../lib-ui/src/shared/interfaces/response';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PollService {
  private readonly URI_API = environment.URLAPI;

  constructor(private readonly httpClient: HttpClient) {}

  getPollById(pollId: string): Observable<IResponse<IPoll>> {
    return this.httpClient.get<IResponse<IPoll>>(
      `${this.URI_API}Poll/${pollId}`
    );
  }

  sendPollResponse(
    pollResponse: IPollResponse
  ): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(
      `${this.URI_API}PollSubmission`,
      pollResponse
    );
  }
}
