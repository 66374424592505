import { inject, Injectable } from '@angular/core';

import { IPoll } from '../../../../../lib-ui/src/shared/interfaces/poll';
import { EObjectStore } from '../enums/objectStore.enum';
import { StartIndexedDbService } from './indexeddb.service';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbPollService {
  private readonly startIndexedDbService = inject(StartIndexedDbService);
  private readonly db: IDBDatabase | null = this.startIndexedDbService.db;

  // Adiciona um survey ao banco de dados
  public addPoll(item: IPoll): Promise<IPoll> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }
      this.clearDB().then(() => {
        const request = this.initTransaction('readwrite').add(item);

        request.onsuccess = () => resolve(item);

        request.onerror = () =>
          reject(new Error('Erro ao adicionar item ao banco de dados'));
      });
    });
  }

  // Busca poll por id
  public getPollById(pollID: string): Promise<IPoll | null> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readonly').get(pollID);

      request.onsuccess = () => resolve(request?.result ?? null);

      request.onerror = () => reject(new Error('Erro ao buscar itens'));
    });
  }

  // Remove todas as polls
  public clearDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(new Error('Banco de dados não foi inicializado'));
      }

      const request = this.initTransaction('readwrite').clear();

      request.onsuccess = () => resolve();
      request.onerror = () =>
        reject(new Error('Erro ao deletar todos os itens'));
    });
  }

  private initTransaction(
    permission: 'readwrite' | 'readonly'
  ): IDBObjectStore {
    return this.db!.transaction([EObjectStore.POLL], permission).objectStore(
      EObjectStore.POLL
    );
  }
}
