import { Routes } from '@angular/router';
import { InitialSetupComponent } from './initial-setup/initial-setup.component';
import { PollComponent } from './poll/poll.component';
import { getPollResolver } from './shared/services/resolves/poll-resolve';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'setup',
    pathMatch: 'full',
  },
  {
    path: 'setup',
    title: 'Configuração',
    loadComponent: () => InitialSetupComponent,
  },
  {
    path: 'unit/:unitId/poll/:pollId',
    title: 'Pesquisa',
    resolve: {
      poll: getPollResolver,
    },
    loadComponent: () => PollComponent,
  },
];
