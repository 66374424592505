import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { IPollResponse } from '../../../../../../lib-ui/src/shared/interfaces/poll-responses';
import { IPartialUnit } from '../../../../../../lib-ui/src/shared/interfaces/unit';
import { DeviceService } from '../../services/device.service';
import { IndexedDbAnswersService } from '../../services/indexeddb-answers.service';
import { IndexedDbFailedAnswersService } from '../../services/indexeddb-failed-answers.service';
import { IndexedDbResponseSendService } from '../../services/indexeddb-response-send.service';
import { IndexedDbUnitService } from '../../services/indexeddb-unit.service';
import { PollService } from '../../services/poll.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CommonModule],
  providers: [DeviceService],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly deviceService = inject(DeviceService);
  private readonly router = inject(Router);

  private readonly indexedDbAnswersService = inject(IndexedDbAnswersService);
  private readonly indexedDbFailedAnswersService = inject(
    IndexedDbFailedAnswersService
  );
  private readonly indexedDbResponseSendService = inject(
    IndexedDbResponseSendService
  );
  private readonly pollService = inject(PollService);
  private readonly indexedDbUnitService = inject(IndexedDbUnitService);

  networkInterval: any;

  // Antes: hasNetwork = signal<boolean>(false);
  // Agora: Apenas uma variável boolean normal
  hasNetwork: boolean = false;

  // Antes: unit = input.required<IPartialUnit>();
  // Agora: use @Input() para propriedades de entrada
  @Input() unit!: IPartialUnit;

  answers: IDBValidKey[] = [];
  answersSent: IDBValidKey[] = [];
  answersNotSent: IDBValidKey[] = [];

  sendingAnswers = false;

  private subs!: Subscription;

  ngOnInit(): void {
    this.networkInterval = setInterval(() => {
      this.getCountAnswers();
      this.getAnswersNotSent();
    }, 15000);

    this.getCountAnswers();
    this.getAnswersNotSent();
    this.getAllResponseSend();
  }

  ngOnDestroy(): void {
    clearInterval(this.networkInterval);
    this.subs?.unsubscribe();
  }

  getCountAnswers(): void {
    this.indexedDbAnswersService.getAllAnswersIds().then((answers) => {
      this.answers = answers;
      if (!this.sendingAnswers) {
        this.handlerAnswers();
      }
    });
  }

  getAnswersNotSent(): void {
    this.indexedDbFailedAnswersService
      .getAllFailedAnswersIds()
      .then((answers) => {
        this.answersNotSent = answers;
      });
  }

  handlerAnswers(): void {
    this.hasNetwork = navigator.onLine;

    if (navigator.onLine && this.answers.length) {
      this.sendingAnswers = true;
      this.indexedDbAnswersService
        .getAnswerById(this.answers[0] as string)
        .then((answer) => {
          if (answer) this.sendAnswer(answer);
        });
    } else if (navigator.onLine && this.answersNotSent.length) {
      this.sendingAnswers = true;
      this.indexedDbFailedAnswersService
        .getFailedAnswerById(this.answersNotSent[0] as string)
        .then((answer) => {
          if (answer) this.sendAnswer(answer);
        });
    } else {
      this.sendingAnswers = false;
    }
  }

  sendAnswer(pollResponse: IPollResponse): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.subs = this.pollService.sendPollResponse(pollResponse).subscribe({
      next: () => {
        this.deleteAnswersById(pollResponse.id);
        this.deleteFailedAnswersById(pollResponse.id);
        this.addAnswerSend(pollResponse.id);
      },
      error: () => {
        if (!this.answersNotSent.some((value) => value === pollResponse.id)) {
          this.addFailedAnswers(pollResponse);
        }
        this.sendingAnswers = false;
      },
    });
  }

  addFailedAnswers(pollResponse: IPollResponse): void {
    this.indexedDbFailedAnswersService
      .addFailedAnswers(pollResponse)
      .then(() => {
        this.deleteAnswersById(pollResponse.id);
      });
  }

  addAnswerSend(id: string): void {
    this.indexedDbResponseSendService
      .addResponseSend({ id })
      .then(() => this.getAllResponseSend());
  }

  getAllResponseSend(): void {
    this.indexedDbResponseSendService.getAllResponseSend().then((res) => {
      this.answersSent = res;
    });
  }

  deleteAnswersById(id: string): void {
    if (this.answers.some((value) => value === id)) {
      this.indexedDbAnswersService.deleteAnswersById(id).then(() => {
        this.answers = this.answers.filter((answer) => answer !== id);
        this.handlerAnswers();
      });
    }
  }

  deleteFailedAnswersById(id: string): void {
    if (this.answersNotSent.some((value) => value === id)) {
      this.indexedDbFailedAnswersService
        .deleteFailedAnswersById(id)
        .then(() => {
          this.answersNotSent = this.answersNotSent.filter(
            (answer) => answer !== id
          );
          this.handlerAnswers();
        });
    }
  }

  async unLinkDevice(): Promise<void> {
    const { success } = await lastValueFrom(
      this.deviceService.unLinkDevice(this.unit.device.id).pipe(take(1))
    );

    if (success) this.router.navigate([`/setup`]);
  }
}
