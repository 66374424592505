import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Observable } from 'rxjs';
import { IPoll } from '../../../../../../lib-ui/src/shared/interfaces/poll';
import { IndexedDbPollService } from '../indexeddb-poll.service';

export const getPollResolver: ResolveFn<
  Promise<IPoll | null> | Observable<IPoll | null>
> = (route, state) => {
  const indexedDbPollService = inject(IndexedDbPollService);

  return indexedDbPollService.getPollById(route.params['pollId']);
};
