import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../../../../lib-ui/src/shared/interfaces/response';
import { environment } from '../../../environments/environment';

@Injectable()
export class DeviceService {
  private readonly URI_API = environment.URLAPI + 'Devices';

  constructor(private readonly httpClient: HttpClient) {}

  linkDevice(
    deviceId: string,
    latitude: string,
    longitude: string
  ): Observable<IResponse<any>> {
    return this.httpClient.patch<IResponse<any>>(
      `${this.URI_API}/link/?DeviceId=${deviceId}&Latitude=${latitude}&Longitude=${longitude}`,
      {}
    );
  }

  unLinkDevice(deviceId: string): Observable<IResponse<any>> {
    return this.httpClient.patch<IResponse<any>>(
      `${this.URI_API}/unlink/${deviceId}`,
      {}
    );
  }
}
