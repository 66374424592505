import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from '../../../../../lib-ui/src/shared/interfaces/response';
import { IPartialUnit } from '../../../../../lib-ui/src/shared/interfaces/unit';
import { environment } from '../../../environments/environment';

@Injectable()
export class UnitService {
  private readonly URI_API = environment.URLAPI + 'Branch';

  constructor(private readonly httpClient: HttpClient) {}

  getUnits(searchText: string): Observable<IResponse<IPartialUnit[]>> {
    return this.httpClient.get<IResponse<IPartialUnit[]>>(
      `${this.URI_API}/with-devices?SearchString=${searchText}`
    );
  }
}
