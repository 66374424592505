import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationRef,
  ErrorHandler,
  isDevMode,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { StartIndexedDbService } from './shared/services/indexeddb.service';
import { GlobalErrorHandler } from './shared/services/interceptors/global-error-handler.service';

export function initializeIndexedDb(
  startIndexedDbService: StartIndexedDbService
) {
  return (): Promise<void> => startIndexedDbService.initDb();
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    AppComponent,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    StartIndexedDbService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeIndexedDb,
      deps: [StartIndexedDbService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class AppModule {
  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent);
  }
}
